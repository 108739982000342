
import { Component, Vue } from 'vue-property-decorator';

// Utilities
import RoundUpBoosterCard from './RoundUpBoosterCard.vue';
import { FeatureModule } from '@/store/modules';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { MatchingService } from '@/services';
import { IBoosters } from '@/types/offer.types';

@Component({
  components: { RoundUpBoosterCard }
})
export default class RoundUpBoosters extends Vue {
  isOffersEnabled = FeatureModule.getIsOffersEnabled;
  currentTenant = tenants[ActiveTenant];
  roundingBoosts: IBoosters[] = [];
  roundUpBoosterCause = this.currentTenant.roundUpBoosterCause;
  textColor = this.currentTenant.roundUps.textColor;
  titleColor = this.currentTenant.roundUps.titleColor;

  async created(): Promise<void> {
    try {
      if (this.isOffersEnabled) this.roundingBoosts = await MatchingService.fetchBoosters();
    } catch (err) {
      console.log(err);
      if (
        (err as { response?: { statusCode: number } })?.response?.statusCode === 403 ||
        (typeof err === 'string' && err.includes('403'))
      ) {
        console.log(`Error: ${err}`);
        console.log('User (likely) does not have any roundups on their account');
        return;
      }
    }
    // TODO: Dirty, sneaky work around :)
    // this.$notifyError('Something went wrong');
  }
}
