export const getArrowUp = (fill = '#e5bc00', secondaryFill = '#000'): string => {
  let svgData = `<svg xmlns="http://www.w3.org/2000/svg" width="89" height="89" viewBox="0 0 89 89">
  <g id="Group_736" data-name="Group 736" transform="translate(-13.297 731.52) rotate(-90)">
    <g id="Path_1947" data-name="Path 1947" transform="translate(642.52 13.297)" fill="${fill}">
      <path d="M 44.5 87 C 38.76165390014648 87 33.19602584838867 85.8765869140625 27.95769309997559 83.66095733642578 C 22.89706611633301 81.52049255371094 18.35173416137695 78.455810546875 14.44795989990234 74.55204010009766 C 10.54418659210205 70.64826965332031 7.479506492614746 66.10293579101562 5.33903980255127 61.04230499267578 C 3.123413324356079 55.80397415161133 2 50.23834609985352 2 44.5 C 2 38.76165390014648 3.123413324356079 33.19602584838867 5.33903980255127 27.95769309997559 C 7.479506492614746 22.89706611633301 10.54418659210205 18.35173416137695 14.44795989990234 14.44795989990234 C 18.35173416137695 10.54418659210205 22.89706611633301 7.479506492614746 27.95769309997559 5.33903980255127 C 33.19602584838867 3.123413324356079 38.76165390014648 2 44.5 2 C 50.23834609985352 2 55.80397415161133 3.123413324356079 61.04230499267578 5.33903980255127 C 66.10293579101562 7.479506492614746 70.64826965332031 10.54418659210205 74.55204010009766 14.44795989990234 C 78.455810546875 18.35173416137695 81.52049255371094 22.89706611633301 83.66095733642578 27.95769309997559 C 85.8765869140625 33.19602584838867 87 38.76165390014648 87 44.5 C 87 50.23834609985352 85.8765869140625 55.80397415161133 83.66095733642578 61.04230499267578 C 81.52049255371094 66.10293579101562 78.455810546875 70.64826965332031 74.55204010009766 74.55204010009766 C 70.64826965332031 78.455810546875 66.10293579101562 81.52049255371094 61.04230499267578 83.66095733642578 C 55.80397415161133 85.8765869140625 50.23834609985352 87 44.5 87 Z" stroke="none"/>
      <path d="M 44.5 4 C 39.03076934814453 4 33.72727584838867 5.070259094238281 28.73680114746094 7.181045532226562 C 23.91456604003906 9.220680236816406 19.58292388916016 12.14143371582031 15.8621826171875 15.8621826171875 C 12.14143371582031 19.58292388916016 9.220680236816406 23.91456604003906 7.181045532226562 28.73680114746094 C 5.070259094238281 33.72727584838867 4 39.03076934814453 4 44.5 C 4 49.96923065185547 5.070259094238281 55.27272415161133 7.181045532226562 60.26319885253906 C 9.220680236816406 65.08543395996094 12.14143371582031 69.41707611083984 15.8621826171875 73.1378173828125 C 19.58292388916016 76.85856628417969 23.91456604003906 79.77931976318359 28.73680114746094 81.81895446777344 C 33.72727584838867 83.92974090576172 39.03076934814453 85 44.5 85 C 49.96923065185547 85 55.27272415161133 83.92974090576172 60.26319885253906 81.81895446777344 C 65.08543395996094 79.77931976318359 69.41707611083984 76.85856628417969 73.1378173828125 73.1378173828125 C 76.85856628417969 69.41707611083984 79.77931976318359 65.08543395996094 81.81895446777344 60.26319885253906 C 83.92974090576172 55.27272415161133 85 49.96923065185547 85 44.5 C 85 39.03076934814453 83.92974090576172 33.72727584838867 81.81895446777344 28.73680114746094 C 79.77931976318359 23.91456604003906 76.85856628417969 19.58292388916016 73.1378173828125 15.8621826171875 C 69.41707611083984 12.14143371582031 65.08543395996094 9.220680236816406 60.26319885253906 7.181045532226562 C 55.27272415161133 5.070259094238281 49.96923065185547 4 44.5 4 M 44.5 0 C 69.07666015625 0 89 19.92333984375 89 44.5 C 89 69.07666015625 69.07666015625 89 44.5 89 C 19.92333984375 89 0 69.07666015625 0 44.5 C 0 19.92333984375 19.92333984375 0 44.5 0 Z" stroke="none" fill="${secondaryFill}"/>
    </g>
    <g id="Group_578" data-name="Group 578" transform="translate(662.396 35.161)" >
      <path id="Path_42" data-name="Path 42" d="M363.943,31.374a2.554,2.554,0,0,1,3.622,0l20.768,20.757a2.553,2.553,0,0,1,0,3.623L367.565,76.522a2.554,2.554,0,0,1-3.622,0l-2.372-2.372a2.566,2.566,0,0,1,.043-3.664l12.874-12.265h-30.7a2.557,2.557,0,0,1-2.564-2.564V52.239a2.557,2.557,0,0,1,2.564-2.564h30.7L361.615,37.41a2.548,2.548,0,0,1-.043-3.664Z" transform="translate(-341.22 -30.62)" fill="${secondaryFill}"/>
    </g>
  </g>
  </svg>`;
  svgData = encodeURIComponent(svgData);
  return `data:image/svg+xml;charset=UTF-8,${svgData}`;
};
