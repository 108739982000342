
import { Component, Vue } from 'vue-property-decorator';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Utilities
import { getArrowUp } from '../../assets/icons/arrow-up';

@Component
export default class RoundUpBanner extends Vue {
  currentTenant = tenants[ActiveTenant];
  arrowUpIcon = getArrowUp(
    this.currentTenant.roundUps.arrowUpIconColor,
    this.currentTenant.roundUps.arrowUpIconColorSecondary
  );
  bannerColor = this.currentTenant.roundUps.bannerColor;
  bannerText = this.currentTenant.roundUps.bannerText;
  bannerTextSecondary = this.currentTenant.roundUps.bannerTextSecondary;
  bannerTextColor = this.currentTenant.roundUps.bannerTextColor;
}
