
import { Component, Prop, Vue } from 'vue-property-decorator';

// Utilities
import { RoundUpService } from '@/services';
import { RoundUpModule } from '@/store/modules';
import { tenants } from '../../../tenant-config';
import { ActiveTenant } from '../../../active-tenant';
import { mdiHelpCircle } from '@mdi/js';

@Component
export default class RoundUpMinMaxAmountTooltip extends Vue {
  @Prop({ default: true })
  isMinimumMonthlyText!: boolean;

  currency: any;
  ecosystemMinimum = RoundUpModule.getEcosystemMinimum;
  blueTextColor = tenants[ActiveTenant].blueTextColor;

  icons = {
    helpCircle: mdiHelpCircle
  };

  async created() {
    if (!this.ecosystemMinimum) this.getEcosystemMinimumAmount();
  }

  async getEcosystemMinimumAmount(): Promise<void> {
    try {
      await RoundUpService.getRoundUpGlobalSettings();
      this.ecosystemMinimum = RoundUpModule.getEcosystemMinimum;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }
}
