
import { Component, Vue } from 'vue-property-decorator';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component
export default class RoundUpAbout extends Vue {
  currentTenant = tenants[ActiveTenant];
  email = this.currentTenant.email;
  termsLink = this.currentTenant.links.termsConditions;
  privacyLink = this.currentTenant.links.privacyPolicy;
}
