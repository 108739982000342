
import { Component, Vue } from 'vue-property-decorator';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Utilities
import { mdiUnfoldMoreHorizontal } from '@mdi/js';
import { PeriodSelector } from '@/components/common';
import { RoundUpService } from '@/services';

// Types
import { ITimePeriod } from '@/types/base.types';
import { ICharge } from '@/types/round-up.types';

@Component({ components: { PeriodSelector } })
export default class RoundUpPaymentHistory extends Vue {
  charges: ICharge[] = [];
  currency!: any;
  currentTenant = tenants[ActiveTenant];
  dayjs!: any;
  headers = [
    { text: 'DATE', value: 'createdAt', icon: mdiUnfoldMoreHorizontal },
    { sortable: false, text: 'NO. OF TRANSACTIONS', value: 'transactionNumber' },
    { text: 'AMOUNT CHARGED', value: 'chargedTotal' },
    { sortable: false, text: 'CARD CHARGED', value: 'paymentMethod.last4Digits' }
  ];
  icons = {
    mdiUnfoldMoreHorizontal
  };
  loading = false;
  options = {
    itemsPerPage: 50,
    page: 1
  };
  selectedPeriod: ITimePeriod = { startDate: undefined, endDate: undefined };
  sortBy = ['createdAt'];
  sortDesc = true;
  titleColor = this.currentTenant.roundUps.titleColor;
  totalItems = 0;

  onSelect(selected: ITimePeriod): void {
    this.options.page = 1;
    this.selectedPeriod = selected;
    this.getCharges();
  }

  created(): void {
    this.getCharges();
  }

  async getCharges(): Promise<void> {
    // NOTE: When user changes sort option - sortDesc
    // doesn't change immediately. Had to add a timeout here
    // to make sure it updates before we make a call to api.
    setTimeout(async () => {
      this.loading = true;
      try {
        const response = await RoundUpService.getCharges(
          this.options,
          this.selectedPeriod,
          (this.sortDesc ? '-' : '') + this.sortBy[0]
        );
        this.charges = response.data;
        this.totalItems = response.pagination.totalItems;
      } catch (err) {
        this.$notifyError("Couldn't load payment history");
      }
      this.loading = false;
    }, 10);
  }
}
