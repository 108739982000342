
import { Component, Vue } from 'vue-property-decorator';
import { mdiArrowUpCircleOutline } from '@mdi/js';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Components
import BlueBorderedButton from '@/components/buttons/BlueBordered.vue';
import RoundUpToolTip from '@/components/round-up/RoundUpToolTip.vue';

// Utilities
import { MemberService, RoundUpService, TransactionService } from '@/services';

// Types
import { CardDetail } from '@/types/user.types';
import { IBasePaged } from '@/types/base.types';
import { RoundUpModule } from '@/store/modules';

@Component({
  components: { BlueBorderedButton, RoundUpToolTip }
})
export default class RoundUpDashboard extends Vue {
  currentTenant = tenants[ActiveTenant];
  currency!: any;
  icon = mdiArrowUpCircleOutline;
  progressBarColor = this.currentTenant.roundUps.progressBarColor;
  textColor = this.currentTenant.roundUps.textColor;

  // Round up rule
  isPaused = RoundUpModule.getRoundUp?.paused ?? false;
  userMaximumAmount = RoundUpModule.getRoundUp?.maximumRoundUps ?? null;
  userMinimumAmount = RoundUpModule.getRoundUp?.minimumRoundUps ?? null;

  roundUpStats = RoundUpModule.getRoundUpStats;

  cards: IBasePaged<CardDetail> = {
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
    items: []
  };
  monthlyNumberTransactions = 0;
  annualNumberTransactions = 0;

  handleClick() {
    this.$router.replace('/settings/round-up-program/');
  }

  getDefaultLast4Digits(cardsPaginated: IBasePaged<CardDetail>): string | undefined {
    if (!cardsPaginated || !Array.isArray(cardsPaginated.items)) {
      return undefined;
    }
    const defaultCard = cardsPaginated.items.find((item: CardDetail) => item.isDefault);
    return defaultCard?.last4Digits;
  }

  async fetchLinkedCards(): Promise<void> {
    try {
      const response = await MemberService.getCards();
      this.cards = response;
    } catch (error) {
      console.log(error);
    }
  }

  async fetchRoundUpStats(): Promise<void> {
    await RoundUpService.getRoundUpStats();
    this.roundUpStats = RoundUpModule.getRoundUpStats;
  }

  async fetchRoundUpRuleForUser(): Promise<void> {
    await RoundUpService.getRule();
    this.isPaused = RoundUpModule.getRoundUp?.paused ?? false;
    this.userMinimumAmount = RoundUpModule.getRoundUp?.minimumRoundUps ?? null;
    this.userMaximumAmount = RoundUpModule.getRoundUp?.maximumRoundUps ?? null;
  }

  async getNumberOfTransactions(): Promise<void> {
    try {
      const response = await TransactionService.getNumberOfTransactions();
      this.monthlyNumberTransactions = response.currentMonth;
      this.annualNumberTransactions = response.currentYear;
    } catch (error) {
      console.log(error);
    }
  }

  get monthProgress(): number {
    const currentMonth = new Date().getMonth() + 1;
    return (currentMonth * 100) / 12;
  }

  created(): void {
    Promise.all([
      this.fetchRoundUpStats(),
      this.fetchLinkedCards(),
      this.fetchRoundUpRuleForUser(),
      this.getNumberOfTransactions()
    ]);
  }
}
