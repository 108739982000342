
import { Component, Prop, Vue } from 'vue-property-decorator';

// Utilities
import { getArrowUp } from '@/assets/icons/arrow-up';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { IBoosters } from '@/types/offer.types';

@Component
export default class RoundUpBoosterCard extends Vue {
  @Prop() roundingBoost!: IBoosters;
  arrowUpIcon = getArrowUp(
    tenants[ActiveTenant].roundUps.arrowUpIconColor,
    tenants[ActiveTenant].roundUps.arrowUpIconColorSecondary
  );
  roundUpBoosterCause = tenants[ActiveTenant].roundUpBoosterCause;
}
